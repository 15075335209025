window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail);
    
    if (event.detail.screenName === 'fahrzeugauswahl') {
        let hsntsn_hsn = document.querySelector('[data-variable="HSN"]');
        let hsntsn_tsn = document.querySelector('[data-variable="TSN"]');
        hsntsn_hsn.addEventListener('input', handleHSNTSNChange);
        hsntsn_tsn.addEventListener('input', handleHSNTSNChange);
        
        
        // get the Modell based on Marke
        var liste_marke = document.querySelector('[data-variable="liste_marke"]');
        liste_marke.addEventListener('change', handleModell);
        
        
        // get the KW based on Modell and Marke
        var liste_modell = document.querySelector('[data-variable="liste_modell"]');
        liste_modell.addEventListener('change', handleKW);
        
        
        // get the Motor Codes based on Marke,Modell,KW and Hubraum
         let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
         liste_kWHubraum.addEventListener('change', handleMotorCodes);
         
        if(hsntsn_hsn.value == '' && hsntsn_tsn.value == ''){
        let liste_marke = document.querySelector('[data-variable="liste_marke"]');
        let liste_modell = document.querySelector('[data-variable="liste_modell"]');
        
        let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
        let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
        
        liste_marke.innerHTML = '';
        liste_modell.innerHTML = '';
        liste_motorcodes.innerHTML = '';            
        liste_kWHubraum.innerHTML = '';
        
        const defaultOption = document.createElement('option');
        defaultOption.textContent = "Bitte auswählen";
        defaultOption.value = "";
        defaultOption.disabled = true; // Make the default option disabled
        defaultOption.selected = true; 
        
        liste_marke.appendChild(defaultOption);
        liste_marke.disabled = true; 
        fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchmaker/")
        .then(response => response.json())
        .then(data =>{
        console.log(data.length)
        
        
        data.forEach(function(d) {
            let option = document.createElement('option');
            option.value = d;
            option.textContent = d;
            liste_marke.appendChild(option);
            liste_marke.disabled = false; 
        });
         
        
        
        } )
        .catch(error => console.error(error));
    }
      
            window.addEventListener('heyflow-change', (event) => {
                console.log('heyflow change fieldsSimple:', event.detail.fieldsSimple);
                console.log('heyflow change:', event.detail);

             
               

                 let hsntsn_hsn = document.querySelector('[data-variable="HSN"]');
                 let hsntsn_tsn = document.querySelector('[data-variable="TSN"]');
                
                 console.log('hsntsn_hsn>>>', hsntsn_hsn.value);
                 console.log('hsntsn_tsn>>>', hsntsn_tsn.value);

                
                 
            });

 }
    
});

function handleHSNTSNChange() {
    let hsntsn_hsn = document.querySelector('[data-variable="HSN"]');
    let hsntsn_tsn = document.querySelector('[data-variable="TSN"]');
    console.log("HSN or TSN changed!");
    console.log("HSN Value:", hsntsn_hsn.value);
    console.log("TSN Value:", hsntsn_tsn.value);
    
    if (hsntsn_hsn.value !== '' && hsntsn_tsn.value !== '') {
        fetch(`https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchVehecle/${hsntsn_hsn.value}&${hsntsn_tsn.value}`)
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(data => {
                let liste_marke = document.querySelector('[data-variable="liste_marke"]');
                let liste_modell = document.querySelector('[data-variable="liste_modell"]');
                
                let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
                let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');

                console.log('data>>>',JSON.parse(data));
                const jsondata = JSON.parse(data);
                // Update Marke
                liste_marke.innerHTML = '';
                let defaultOptionMarke = document.createElement('option');
                defaultOptionMarke.textContent = jsondata.marke;
                defaultOptionMarke.value = jsondata.marke;
                defaultOptionMarke.selected = true;
                liste_marke.appendChild(defaultOptionMarke);
                
                // Update Modell
                liste_modell.innerHTML = '';
                let defaultOptionModell = document.createElement('option');
                defaultOptionModell.textContent = jsondata.model;
                defaultOptionModell.value = jsondata.model;
                defaultOptionModell.selected = true;
                liste_modell.appendChild(defaultOptionModell);
                //
                
                // Update kW/Hubraum
                liste_kWHubraum.innerHTML = '';
                let defaultOptionkWHubraum = document.createElement('option');
                defaultOptionkWHubraum.textContent = jsondata.kWHubraum;
                defaultOptionkWHubraum.value = jsondata.kWHubraum;
                defaultOptionkWHubraum.selected = true;
                liste_kWHubraum.appendChild(defaultOptionkWHubraum);
                
                
               console.log(jsondata.motorcodes.length);
                if(jsondata.motorcodes.length <= 2){
                    liste_motorcodes.innerHTML = '';
                

                jsondata.motorcodes.forEach(function(d) {
                let option = document.createElement('option');
                option.value = d;
                option.textContent = d;
                liste_motorcodes.appendChild(option);
                liste_motorcodes.disabled = false; 
                
                });
                    
                }
                else{
                    
                    liste_motorcodes.innerHTML = '';
                let defaultOptionMotorcodes = document.createElement('option');
                defaultOptionMotorcodes.textContent = "Bitte auswählen";
                defaultOptionMotorcodes.value = "";
                defaultOptionMotorcodes.selected = true;
                liste_motorcodes.appendChild(defaultOptionMotorcodes);

                jsondata.motorcodes.forEach(function(d) {
                let option = document.createElement('option');
                option.value = d;
                option.textContent = d;
                liste_motorcodes.appendChild(option);
                liste_motorcodes.disabled = false; 
                
                });
                    
                }
                
                // Update Motor Codes
                /*liste_motorcodes.innerHTML = '';
                let defaultOptionMotorcodes = document.createElement('option');
                defaultOptionMotorcodes.textContent = "Bitte auswählen";
                defaultOptionMotorcodes.value = "";
                defaultOptionMotorcodes.selected = true;
                liste_motorcodes.appendChild(defaultOptionMotorcodes);

                jsondata.motorcodes.forEach(function(d) {
                let option = document.createElement('option');
                option.value = d;
                option.textContent = d;
                liste_motorcodes.appendChild(option);
                liste_motorcodes.disabled = false; 
                
                });*/
            })
            .catch(error => console.error('Fetch error:', error));
                }
                
                
                else if(hsntsn_hsn.value == '' && hsntsn_tsn.value == ''){
                    let liste_marke = document.querySelector('[data-variable="liste_marke"]');
                    let liste_modell = document.querySelector('[data-variable="liste_modell"]');
                    
                    let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
                    let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
                    
                    liste_marke.innerHTML = '';
                    liste_modell.innerHTML = '';
                    
                    liste_motorcodes.innerHTML = '';
                    liste_kWHubraum.innerHTML = '';
                    const defaultOption = document.createElement('option');
                    defaultOption.textContent = "Bitte auswählen";
                    defaultOption.value = "";
                    defaultOption.disabled = true; // Make the default option disabled
                    defaultOption.selected = true; 
                    
                    liste_marke.appendChild(defaultOption);
                    liste_marke.disabled = true; 
                    fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchmaker/")
                    .then(response => response.json())
                    .then(data =>{
                    console.log(data.length)
                    
                    
                    data.forEach(function(d) {
                        let option = document.createElement('option');
                        option.value = d;
                        option.textContent = d;
                        liste_marke.appendChild(option);
                        liste_marke.disabled = false; 
                    });
                     
                    
                    
                    } )
                    .catch(error => console.error(error));
                }
    
    // Perform your fetch or update logic here
}

function handleModell(){
    
    let liste_modell = document.querySelector('[data-variable="liste_modell"]');
    
    let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
    let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
    
    liste_modell.innerHTML = '';
    
    liste_motorcodes.innerHTML = '';
    liste_kWHubraum.innerHTML = '';
    let liste_marke_value = document.querySelector('[data-variable="liste_marke"]');
    console.log(liste_marke_value.value);
    
    if(liste_marke_value.value != ''){
        let liste_modell = document.querySelector('[data-variable="liste_modell"]');
            const defaultOption = document.createElement('option');
            defaultOption.textContent = "Bitte auswählen";
            defaultOption.value = "";
            defaultOption.disabled = true; // Make the default option disabled
            defaultOption.selected = true; 
            
            liste_modell.appendChild(defaultOption);
            liste_modell.disabled = true; 
            let encodedName = encodeURIComponent(liste_marke_value.value);
	       fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchModell/?modell=" + encodedName)
	       .then(response => response.json())
	       .then(data => {
		    console.log('data',data);
		    data.forEach(function(d) {
            let option = document.createElement('option');
            option.value = d;
            option.textContent = d;
            liste_modell.appendChild(option);
            liste_modell.disabled = false;
            });

		   })
	     .catch(error => console.error(error));
    }
}

function handleKW(){
            let liste_marke = document.querySelector('[data-variable="liste_marke"]');
            let liste_modell = document.querySelector('[data-variable="liste_modell"]');
            
            let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
            let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
            
           
            liste_motorcodes.innerHTML = '';
            liste_kWHubraum.innerHTML = '';
            
            const defaultOption = document.createElement('option');
            defaultOption.textContent = "Bitte auswählen";
            defaultOption.value = "";
            defaultOption.disabled = true; // Make the default option disabled
            defaultOption.selected = true; 
            
            liste_kWHubraum.appendChild(defaultOption);
            liste_kWHubraum.disabled = true; 
            
             if(liste_modell.value != ''){
                 
                 
                   let encodedName = encodeURIComponent(liste_modell.value);
        	       fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchKW/?modell=" + encodedName)
        	       .then(response => response.json())
        	       .then(data => {
        		    console.log('data',data);
        		    
        		    data.forEach(function(d) {
                    let option = document.createElement('option');
                    option.value = d;
                    option.textContent = d;
                    liste_kWHubraum.appendChild(option);
                    liste_kWHubraum.disabled = false; 
                    });
        
        		    })
        	        .catch(error => console.error(error));
                         
             }
}


function handleMotorCodes(){
    let liste_marke = document.querySelector('[data-variable="liste_marke"]');
    let liste_modell = document.querySelector('[data-variable="liste_modell"]');
    
    let liste_motorcodes = document.querySelector('[data-variable="motorcodes"]');
    let liste_kWHubraum = document.querySelector('[data-variable="kWHubraum"]');
    
    
    
    liste_motorcodes.innerHTML = '';
    
    const defaultOption = document.createElement('option');
    defaultOption.textContent = "Bitte auswählen";
    defaultOption.value = "";
    defaultOption.disabled = true; // Make the default option disabled
    defaultOption.selected = false; 
    
    liste_motorcodes.appendChild(defaultOption);
    liste_motorcodes.disabled = true; 
    
    if( liste_marke.value != '' && liste_modell.value != '' && liste_kWHubraum.value != ''){
        
        let encodedModellName = encodeURIComponent(liste_modell.value);
        
        let encodedMarkeName = encodeURIComponent(liste_marke.value);
        let encodedkWHubraumName = encodeURIComponent(liste_kWHubraum.value);
        fetch("https://deinmotorschaden.my.salesforce-sites.com/Heyflow/services/apexrest/fetchMotorCodes/?kWHubraum=" + encodedkWHubraumName+"&modell="+encodedModellName+"&marke="+encodedMarkeName)
       .then(response => response.json())
       .then(data => {
	    console.log('data',data);
	    
	    data.forEach(function(d) {
        let option = document.createElement('option');
        option.value = d;
        option.textContent = d;
        liste_motorcodes.appendChild(option);
        liste_motorcodes.disabled = false;
        });

	    })
         .catch(error => console.error(error));
        
    }
    
    
}

